/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BillingStatus } from './BillingStatus';
import {
    BillingStatusFromJSON,
    BillingStatusFromJSONTyped,
    BillingStatusToJSON,
} from './BillingStatus';
import type { FeaturedWorkspaceCategory } from './FeaturedWorkspaceCategory';
import {
    FeaturedWorkspaceCategoryFromJSON,
    FeaturedWorkspaceCategoryFromJSONTyped,
    FeaturedWorkspaceCategoryToJSON,
} from './FeaturedWorkspaceCategory';
import type { InitialCreditResponse } from './InitialCreditResponse';
import {
    InitialCreditResponseFromJSON,
    InitialCreditResponseFromJSONTyped,
    InitialCreditResponseToJSON,
} from './InitialCreditResponse';
import type { ResearchPurpose } from './ResearchPurpose';
import {
    ResearchPurposeFromJSON,
    ResearchPurposeFromJSONTyped,
    ResearchPurposeToJSON,
} from './ResearchPurpose';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface Workspace
 */
export interface Workspace {
    /**
     * Entity tag for optimistic concurrency control. To be set during a read-modify-write to ensure that the client has not attempted to modify a changed resource.
     * @type {string}
     * @memberof Workspace
     */
    etag?: string;
    /**
     * DEPRECATED. A workspace has both an AoU UI name (displayName) and a terraName.  Use one of these to be explicit about which is desired.
     * @type {string}
     * @memberof Workspace
     */
    name: string;
    /**
     * The name of the workspace as displayed in the UI.  This name is not known to Terra, so it's necessary to use `terraName` and/or `namespace` for Terra calls. Formerly known simply as "name".
     * @type {string}
     * @memberof Workspace
     */
    displayName?: string;
    /**
     * 
     * @type {string}
     * @memberof Workspace
     */
    namespace?: string;
    /**
     * The name of this workspace as stored in Terra.  This is also part of the workspace component of the URL, and is therefore user-visible. Formerly known as "id" in some contexts, but this was confusing because this does not refer to the AoU or Terra database IDs corresponding to the workspace. When a workspace is initially created, this is equal to the "slugified" version of displayName (example: "My Workspace" becomes "myworkspace"). However, when the workspace is renamed (in the AoU RWB UI) the terraName does not change.
     * @type {string}
     * @memberof Workspace
     */
    terraName?: string;
    /**
     * 
     * @type {string}
     * @memberof Workspace
     */
    cdrVersionId?: string;
    /**
     * # TODO: Remove this field. Deprecated. Use creatorUser instead.
     * @type {string}
     * @memberof Workspace
     */
    creator?: string;
    /**
     * 
     * @type {User}
     * @memberof Workspace
     */
    creatorUser?: User;
    /**
     * 
     * @type {string}
     * @memberof Workspace
     */
    billingAccountName?: string;
    /**
     * 
     * @type {string}
     * @memberof Workspace
     */
    googleBucketName?: string;
    /**
     * 
     * @type {string}
     * @memberof Workspace
     */
    accessTierShortName?: string;
    /**
     * 
     * @type {ResearchPurpose}
     * @memberof Workspace
     */
    researchPurpose?: ResearchPurpose;
    /**
     * 
     * @type {BillingStatus}
     * @memberof Workspace
     */
    billingStatus?: BillingStatus;
    /**
     * Milliseconds since the UNIX epoch.
     * @type {number}
     * @memberof Workspace
     */
    creationTime?: number;
    /**
     * the username of the user to last update a workspace
     * @type {string}
     * @memberof Workspace
     */
    lastModifiedBy?: string;
    /**
     * Milliseconds since the UNIX epoch.
     * @type {number}
     * @memberof Workspace
     */
    lastModifiedTime?: number;
    /**
     * 
     * @type {FeaturedWorkspaceCategory}
     * @memberof Workspace
     */
    featuredCategory?: FeaturedWorkspaceCategory;
    /**
     * Whether an administrator has prevented most actions on the workspace for all users
     * @type {boolean}
     * @memberof Workspace
     */
    adminLocked?: boolean;
    /**
     * Reason submitted by admin for workspace locking
     * @type {string}
     * @memberof Workspace
     */
    adminLockedReason?: string;
    /**
     * the google project used by the workspace for compute and storage
     * @type {string}
     * @memberof Workspace
     */
    googleProject?: string;
    /**
     * 
     * @type {InitialCreditResponse}
     * @memberof Workspace
     */
    initialCredits?: InitialCreditResponse;
    /**
     * Whether a workspace uses Tanagra
     * @type {boolean}
     * @memberof Workspace
     */
    usesTanagra?: boolean;
    /**
     * whether this workspace is a VWB workspace
     * @type {boolean}
     * @memberof Workspace
     */
    vwbWorkspace?: boolean;
}

/**
 * Check if a given object implements the Workspace interface.
 */
export function instanceOfWorkspace(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function WorkspaceFromJSON(json: any): Workspace {
    return WorkspaceFromJSONTyped(json, false);
}

export function WorkspaceFromJSONTyped(json: any, ignoreDiscriminator: boolean): Workspace {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'etag': !exists(json, 'etag') ? undefined : json['etag'],
        'name': json['name'],
        'displayName': !exists(json, 'displayName') ? undefined : json['displayName'],
        'namespace': !exists(json, 'namespace') ? undefined : json['namespace'],
        'terraName': !exists(json, 'terraName') ? undefined : json['terraName'],
        'cdrVersionId': !exists(json, 'cdrVersionId') ? undefined : json['cdrVersionId'],
        'creator': !exists(json, 'creator') ? undefined : json['creator'],
        'creatorUser': !exists(json, 'creatorUser') ? undefined : UserFromJSON(json['creatorUser']),
        'billingAccountName': !exists(json, 'billingAccountName') ? undefined : json['billingAccountName'],
        'googleBucketName': !exists(json, 'googleBucketName') ? undefined : json['googleBucketName'],
        'accessTierShortName': !exists(json, 'accessTierShortName') ? undefined : json['accessTierShortName'],
        'researchPurpose': !exists(json, 'researchPurpose') ? undefined : ResearchPurposeFromJSON(json['researchPurpose']),
        'billingStatus': !exists(json, 'billingStatus') ? undefined : BillingStatusFromJSON(json['billingStatus']),
        'creationTime': !exists(json, 'creationTime') ? undefined : json['creationTime'],
        'lastModifiedBy': !exists(json, 'lastModifiedBy') ? undefined : json['lastModifiedBy'],
        'lastModifiedTime': !exists(json, 'lastModifiedTime') ? undefined : json['lastModifiedTime'],
        'featuredCategory': !exists(json, 'featuredCategory') ? undefined : FeaturedWorkspaceCategoryFromJSON(json['featuredCategory']),
        'adminLocked': !exists(json, 'adminLocked') ? undefined : json['adminLocked'],
        'adminLockedReason': !exists(json, 'adminLockedReason') ? undefined : json['adminLockedReason'],
        'googleProject': !exists(json, 'googleProject') ? undefined : json['googleProject'],
        'initialCredits': !exists(json, 'initialCredits') ? undefined : InitialCreditResponseFromJSON(json['initialCredits']),
        'usesTanagra': !exists(json, 'usesTanagra') ? undefined : json['usesTanagra'],
        'vwbWorkspace': !exists(json, 'vwbWorkspace') ? undefined : json['vwbWorkspace'],
    };
}

export function WorkspaceToJSON(value?: Workspace | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'etag': value.etag,
        'name': value.name,
        'displayName': value.displayName,
        'namespace': value.namespace,
        'terraName': value.terraName,
        'cdrVersionId': value.cdrVersionId,
        'creator': value.creator,
        'creatorUser': UserToJSON(value.creatorUser),
        'billingAccountName': value.billingAccountName,
        'googleBucketName': value.googleBucketName,
        'accessTierShortName': value.accessTierShortName,
        'researchPurpose': ResearchPurposeToJSON(value.researchPurpose),
        'billingStatus': BillingStatusToJSON(value.billingStatus),
        'creationTime': value.creationTime,
        'lastModifiedBy': value.lastModifiedBy,
        'lastModifiedTime': value.lastModifiedTime,
        'featuredCategory': FeaturedWorkspaceCategoryToJSON(value.featuredCategory),
        'adminLocked': value.adminLocked,
        'adminLockedReason': value.adminLockedReason,
        'googleProject': value.googleProject,
        'initialCredits': InitialCreditResponseToJSON(value.initialCredits),
        'usesTanagra': value.usesTanagra,
        'vwbWorkspace': value.vwbWorkspace,
    };
}

